import { defineStore } from "pinia";
// import axios from "axios";
import { useDRMenuStore } from "./DRMenuStore";

// import { transformMenuItems } from "./transFormMenuItems";
export const useDRPermissionStore = defineStore({
  id: "DRpermission",
  state: () => ({
    allowedDRPaths: [],
    isInitialized: false,
  }),
  actions: {
    async initialize() {
      try {
        const DRmenuStore = useDRMenuStore();
        const DRmenuItems = await DRmenuStore.fetchDigiMenuData();
        this.updateAllowedDRPaths(DRmenuItems);
        this.isInitialized = true;
      } catch (error) {
        console.error("Failed to initialize allowed paths:", error);
        throw error;
      }
    },

    updateAllowedDRPaths(transformDRMenuItems) {
      // console.log("Updating allowed DR paths with:", transformDRMenuItems);

      const updatedDRPaths = transformDRMenuItems.reduce((acc, item) => {
        if (item.path) acc.push(item.path.toLowerCase());
        if (item.children) {
          item.children.forEach((child) => {
            if (child.path) acc.push(child.path.toLowerCase());
          });
        }
        return acc;
      }, []);

      this.allowedDRPaths = updatedDRPaths;
      // console.log("Updated Allowed Paths:", this.allowedDRPaths);
    },

    isDRPathAllowed(path) {
      const normalizedPath = path.toLowerCase().replace(/\/$/, "");
      // console.log("Checking if  DR path is allowed:", normalizedPath);
      // console.log("Current DR allowed paths:", this.allowedDRPaths);

      const isDRAllowed = this.allowedDRPaths.some((allowedPath) =>
        normalizedPath.startsWith(allowedPath)
      );

      // console.log("DRPath is allowed:", isDRAllowed);
      return isDRAllowed;
    },
  },
});
