<template>
  <footer class="footer pb-3">
    <div class="container-fluid">
      <div class="row">
        <div class="col-md-12 d-flex justify-content-center align-items-center">
          <a href="https://www.rdep.io " target="_blank"
            ><img src="../../assets/logo.png" alt="" height="24"
          /></a>
          <div class="text">
            ©{{ new Date().getFullYear() }} - All rights reserved by ProenX Pte
            Ltd.
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>

<style>
.footer {
  border-top: 1px solid rgba(152, 166, 173, 0.2);
  padding: 19px 0 20px;
  color: #98a6ad;
}

@media screen and (max-width: 998px) {
  .text {
    text-align: center;
    font-size: 12px;
  }
}
/* class="d-flex text-md-end footer-links d-none d-md-block justify-content-between"*/
</style>
