<template>
  <div id="view">
    <Topbar :menuList="menuItems" />
    <div class="container-fluid mt-1">
      <!-- <router-view></router-view> -->
      <!-- Container for the dynamic content -->
      <div
        class="content-wrapper"
        :class="{ 'loading-overlay': isLoading > 0 }"
      >
        <router-view v-slot="{ Component }">
          <component :is="Component" />
        </router-view>
        <!-- Spinner, shown only when isLoading is true -->
        <div v-if="isLoading > 0" class="spinner-wrapper">
          <div class="spinner-border text-primary" role="status">
            <span class="visually-hidden">Loading...</span>
          </div>
        </div>
      </div>
    </div>
    <Footer id="footer" />
  </div>
</template>

<script setup>
// import { isLoading } from "./store/LoadingState";
import { isLoading } from "@/store/LoadingState";
import Topbar from "../../components/Navbar/TopbarView.vue";
import Footer from "../../components/Footer/FooterView.vue";
// import axios from "axios";
import { ref, onMounted, watch } from "vue";
import { usePermissionStore } from "../../store/helper/menuPaths.js";
import { useMenuStore } from "../../store/helper/posMenuStore";
import { useRoute } from "vue-router";

const menuStore = useMenuStore();
const route = useRoute();
const permissionStore = usePermissionStore();
const menuItems = ref([]);
const isPosMenuRoutes = ref(false);

const fetchMenuData = async () => {
  try {
    const data = await menuStore.fetchMenuData();
    menuItems.value = data;
    permissionStore.updateAllowedPaths(data);
  } catch (error) {
    console.error("Error fetching menu data:", error);
  }
};
watch(
  () => route.fullPath,
  (newFullPath) => {
    isPosMenuRoutes.value = newFullPath.includes("/rdep-receipt");
    if (!isPosMenuRoutes.value) {
      fetchMenuData();
    }
  },
  { immediate: true }
);

onMounted(() => {
  if (isPosMenuRoutes.value !== true) {
    fetchMenuData();
  }
});
</script>

<style scoped>
/* Disable interaction with content while loading */
.loading-overlay {
  pointer-events: none; /* Disable clicks and interactions */
  opacity: 0.5; /* Optional: reduce opacity for a visual cue */
  position: relative;
}

/* Spinner styling */
.spinner-wrapper {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 10;
}
</style>
<style>
@import url("../../style/style.css");
</style>
