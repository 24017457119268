import { defineStore } from "pinia";
// import axios from "axios";
import { useMenuStore } from "./posMenuStore";

// import { transformMenuItems } from "./transFormMenuItems";
export const usePermissionStore = defineStore({
  id: "permission",
  state: () => ({
    allowedPaths: [],
    isInitialized: false,
  }),
  actions: {
    async initialize() {
      try {
        const menuStore = useMenuStore();
        const menuItems = await menuStore.fetchMenuData();
        this.updateAllowedPaths(menuItems);
        this.isInitialized = true;
      } catch (error) {
        console.error("Failed to initialize allowed paths:", error);
        throw error;
      }
    },

    updateAllowedPaths(transformedMenuItems) {
      // console.log("Updating allowed paths with:", transformedMenuItems);

      const updatedPaths = transformedMenuItems.reduce((acc, item) => {
        if (item.path) acc.push(item.path.toLowerCase());
        if (item.children) {
          item.children.forEach((child) => {
            if (child.path) acc.push(child.path.toLowerCase());
          });
        }
        return acc;
      }, []);

      this.allowedPaths = updatedPaths;
      // console.log("Updated Allowed Paths:", this.allowedPaths);
    },

    isPathAllowed(path) {
      const normalizedPath = path.toLowerCase().replace(/\/$/, "");
      // console.log("Checking if path is allowed:", normalizedPath);
      // console.log("Current allowed paths:", this.allowedPaths);

      const isAllowed = this.allowedPaths.some((allowedPath) =>
        normalizedPath.startsWith(allowedPath)
      );

      // console.log("Path is allowed:", isAllowed);
      return isAllowed;
    },
  },
});
